import React, { Component } from 'react'
import Container from '../organisms/PageContainer'
import SEO from '../atoms/seo'

export default class Blogposts extends Component {
    render() {
        return (
            <Container>
                <SEO title="Blogposts | Latest Posts" />
                Blog Posts go here.
            </Container>
        )
    }
}
